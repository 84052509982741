export const domain =
  process.env.GATSBY_ACTIVE_ENV === "production"
    ? "https://cms.coopz.io"
    : "https://coopz-staging-cms.ventzke-media.com"

export const formatRichText = richText => {
  return typeof richText === "string"
    ? richText.replace(/\b\n\b/g, "<br/>")
    : richText.map(({ richText }) => richText.replace(/\b\n\b/g, "<br/>"))
}

export const generateId = () =>
  Math.random()
    .toString(36)
    .substring(2) + new Date().getTime().toString(36)
