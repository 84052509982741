import React, { Fragment, useContext } from "react";
import { Link } from "gatsby";
import { domain } from "../../utils";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import facebook from "../../images/facebook.svg";
import youtube from "../../images/youtube.svg";
import googlePlay from "../../images/google-play-store.svg";
import { globalHistory } from "@reach/router";
import { GlobalStateContext } from "../../context/GlobalContextProvider";

export const Footer = ({ footer, translateSlug }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;

  const { title, logo, copyright, nav } = footer;
  const { location } = globalHistory;
  const path = location.pathname.split("/").slice(2).join("/");

  return (
    <Fragment>
      <div className="footer position-relative footer-index" id="footer_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="title-small border-blue mb-2 mb-md-5">
                <h2 className="text-uppercase">{title}</h2>
              </div>
              <div className="d-table w-100">
                <div className="container-fluid t-cell-middle">
                  <div
                    className="row mt-md-5 mb-3"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <div className="col-12 text-center">
                      <img
                        className="w-100 w-md-50"
                        alt="logo"
                        src={`${domain}${logo.url}`}
                      />
                    </div>
                  </div>
                  <div
                    className="row mb-4 mb-md-5"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <div className="col-12 text-center p-0">
                      <a
                        className="p-0 my-btn-store m-2"
                        href="https://play.google.com/store/apps/details?id=com.coopzapp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img alt="Google Play" src={googlePlay} width="auto" />
                      </a>
                    </div>
                  </div>
                  <div
                    className="row mb-4 footer-meta"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <div className="col-12 col-md-10 offset-md-1 text-left">
                      <div className="row">
                        {nav.map(({ heading, link }, index) => (
                          <div
                            key={index}
                            className={'col-6 col-md-3'}
                          >
                            <h6>{heading}</h6>
                            <ul className="list-unstyled">
                              {link.map(({ label, slug }, index) => (
                                <li key={index}>
                                  <Link
                                    to={`/${language}/${slug && slug.length > 0 ? `${slug}/` : ""}`}
                                  >
                                    {label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}

                        <div
                          className={'col-6 col-md-3'}
                        >
                          <h6>
                            {language === "en"
                              ? "Follow us on"
                              : "Folge uns auf"}
                          </h6>
                          <div className="social-media">
                            <a
                              className="mr-1 mr-md-2 my-btn-social-media"
                              href="https://twitter.com/coopzapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="twitter" src={twitter} />
                            </a>
                            <a
                              className="mx-1 mx-md-2 my-btn-social-media"
                              href="https://www.instagram.com/coopzapp/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="instagram" src={instagram} />
                            </a>
                            <a
                              className="mx-1 mx-md-2 my-btn-social-media"
                              href="https://www.facebook.com/coopzapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="facebook" src={facebook} />
                            </a>
                            <a
                              className="ml-1 ml-md-2 my-btn-social-media"
                              href="https://www.youtube.com/channel/UCxjZkRE9Ne2iDGFhtOqYqFg"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img alt="youtube" src={youtube} />
                            </a>
                          </div>
                          <div className="d-none d-md-block">
                            <div className="dropdown">
                              <a
                                className="btn dropdown-btn w-100 mt-3 mb-2 text-left"
                                href="/"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {language === "en" ? "English" : "Deutsch"}
                                <i className="fa fa-chevron-down" />
                              </a>

                              <div
                                className="dropdown-menu w-100 p-0"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <Link
                                  className="dropdown-item"
                                  to={`/${language === "en" ? "de" : "en"}/`}
                                >
                                  {language === "de" ? "English" : "Deutsch"}
                                </Link>
                              </div>
                            </div>
                            <div>
                              <p>{copyright}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-md-none">
                          <div className="dropdown">
                            <a
                              className="btn dropdown-btn w-100 mt-3 mb-2 text-left"
                              href="/"
                              role="button"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {language === "en" ? "English" : "Deutsch"}
                              <i className="fa fa-chevron-down" />
                            </a>
                            <div
                              className="dropdown-menu w-100 p-0"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to={`/${language === "en" ? "de" : "en"}/${
                                  path.length > 0 &&
                                  path !== "neue-leute-kennenlernen/berlin/" &&
                                  language !== "de"
                                    ? path.toString() + "/"
                                    : ""
                                }`}
                              >
                                {language === "de" ? "English" : "Deutsch"}
                              </Link>
                            </div>
                          </div>
                          <div>
                            <p>{copyright}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
